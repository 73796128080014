import React from "react";

import styled from '@emotion/styled';

  const Icon = styled.svg`
  width: ${props => props.width ? props.width : '20px' };
  height: auto;
  .st0 {
    fill: #0069A6;
  }
`

const LaptopMobileIcon = (props) => {
  return (
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.107 28.885" {...props}>
  <path className="st0" id="laptop-mobile-solid" d="M34.076,7.171H23.7a2.074,2.074,0,0,0-2.031,2.082v17.6A2.03,2.03,0,0,0,23.7,28.885H34.076a2.03,2.03,0,0,0,2.031-2.031V9.252A2.074,2.074,0,0,0,34.076,7.171Zm-1.58,18.1H25.275V10.832H32.5ZM7.221,3.611H25.275V5.416h3.611V2.708A2.712,2.712,0,0,0,26.184,0H6.313a2.712,2.712,0,0,0-2.7,2.708v13.54H.9a.9.9,0,0,0-.9.9v.9a3.62,3.62,0,0,0,3.6,3.611H19.859V16.248H7.221Z" />
</Icon>

  );
};

export default LaptopMobileIcon;