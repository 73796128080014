import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
    Flex,
    Box,
    Heading,
    Text,
    Link
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import PhoneIcon from './PhoneIcon';
import LaptopMobileIcon from './LaptopMobileIcon';
import MapIcon from './MapIcon';

const TagBox = (props) => {
    return (
        <Box p="1" key={true}>
            <Box bg="sage.200" px="2" borderRadius="sm">
                <Text fontSize="xs" mb="0" fontWeight="bold" color="sage.600" textTransform="uppercase" paddingTop="0.125rem">
                    {props.tag}
                </Text>
            </Box>
        </Box>
    )
}

export default function ListingCard(props) {
    return (
        <>
        {props.premiumListing ? (
            <Box 
                w="100%"
                as={motion.div}
                layout 
                initial={{opacity: 0}}
                animate={{ opacity: 1}}
                exit={{ opacity: 0}}
                order="1"
                border="1px solid" borderColor="gray.200" borderRadius="xl"
                boxShadow="lg"
                m="4"
                key={true}
                bg="white"
            >
                <Flex flexDirection="row" justifyContent="flex-start" w="100%" h="100%" flexWrap="wrap">
                    <Box w={{base: '100%', md: '50%'}}>
                        <Box p="4" textAlign="center" bg="poppy.500" display={{base: 'block', md: 'none'}} borderTopRadius="xl">
                            <Text mb="0" color="white" textTransform="uppercase" fontWeight="700">Featured Listing</Text>
                        </Box>
                        {props.listingImage && props.listingImage.publicURL.includes('.svg') ? (
                        <>
                            <Box className='featured-box gatsby-image-wrapper-unset' height="100%" display={{ base: 'none', md: 'block' }}>
                            <img src={props.listingImage.publicURL} alt={props.listingImageAlt ? props.listingImageAlt : ''} style={{marginLeft: 'auto', marginRight: 'auto' }}/>
                            </Box>
                            <Box className='featured-box gatsby-image-wrapper-unset' display={{ base: 'block', md: 'none' }}>
                            <img src={props.listingImage.publicURL} alt={props.listingImageAlt ? props.listingImageAlt : ''} style={{marginLeft: 'auto', marginRight: 'auto' }}/>
                            </Box>
                        </>
                    ) 
                    : (
                        
                        <>
                            <Box className='featured-box gatsby-image-wrapper-unset' height="100%" display={{ base: 'none', md: 'block' }}>
                                <GatsbyImage image={getImage(props.listingImage)} alt={props.listingImageAlt ? props.listingImageAlt : ''} 
                                imgStyle={{ borderRadius: '.75rem 0 0 .75rem' }}/>
                            </Box>
                            <Box className='featured-box gatsby-image-wrapper-unset' display={{ base: 'block', md: 'none' }}>
                                <GatsbyImage image={getImage(props.listingImage)} alt={props.listingImageAlt ? props.listingImageAlt : ''} />
                            </Box>
                        </>
                    )}
                    </Box>
                    <Flex w={{base: '100%', md: '50%'}} h="100%" flexDirection="column" justifyContent={{base: "flex-start", md: "space-between"}}>
                        <Box px="4" py="4">
                            <Heading as="h3" size="2xl" fontWeight="bold" mb="0">
                                    {props.listingName}
                                </Heading>
                                <Text mb="0" fontSize="xl">
                                    {props.city}
                                </Text>
                        </Box>
                        <Box p="4">
                            <Text fontSize="lg">{props.listingDescription}</Text>
                        </Box>
                        <Box>
                            {props.listinPhone && (
                                <Flex px="4" mb="2" w="100%" alignItems="center">
                                <Box mr="2" w="5%">
                                    <PhoneIcon />
                                </Box>
                                <Box><Link href={`tel:${props.listingPhone}`} fontSize="sm">{props.listingPhone}</Link></Box>
                            </Flex>
                            )}
                            {props.listingWebsite && (
                                <Flex px="4" mb="2" w="100%" alignItems="center">
                                <Box mr="2" w="5%">
                                    <LaptopMobileIcon />
                                </Box>
                                <Box>
                                    <Link href={props.listingWebsite} target="_blank" rel="noopener noreferrer" fontSize="sm">{props.listingWebsite}</Link>
                                </Box>
                            </Flex>
                            )}
                            
                            {props.listingStreet && (<Flex px="4" mb="2" w="100%" alignItems="center">
                                <Box mr="2" w="5%">
                                    <MapIcon />
                                </Box>
                                <Box><Link href={props.mapLink} target="_blank" rel="noopener noreferrer" fontSize="sm">
                                {props.listingStreet}<br />{props.listingAddress2 && <>{props.listingAddress2} <br /></>} 
                                {props.listingCity}, {props.listingCity === "Alta" ? ('WY') : ('ID') } {props.listingCity === "Victor" ? 83455 : props.listingCity === "Driggs" ? 83422 : props.listingCity === "Tetonia" ? 83452 : props.listingCity === "Alta" ? 83414 : null }</Link></Box>
                            </Flex> )
                            }
                        </Box>
                        <Flex w="100%" justifyContent="flex-start" flexWrap="wrap" p="2">
                            {/* Tags are an array of objects, so wrap the key and index in {} */}
                                {props.listingTags.map(({eat_listing_tags, index}) => {
                                return (
                                    <>
                                        {eat_listing_tags !== null && (
                                            <TagBox 
                                            key={index}
                                            tag={eat_listing_tags}
                                        />
                                        )}
                                </>
                                )}
                            )}
                        </Flex>
                    </Flex>
                    </Flex>
        </Box>
        ) : props.featuredListing ? (
            <Box p="4" w={{base: '100%', md: '50%', lg: '33.33%'}} as={motion.div}
                layout 
                initial={{opacity: 0}}
                animate={{ opacity: 1}}
                exit={{ opacity: 0}}
                order="2"
            >
            <Flex flexDirection="column" justifyContent="flex-start" w="100%" h="100%" 
                border="1px solid" borderColor="gray.200" borderRadius="xl"
                boxShadow="lg"
                bg="white"
                >
                    <Box bg="blue.500" p="4" borderTopRadius="xl">
                        <Heading as="h3" size="md" fontWeight="bold" color="white" mb="0">
                            {props.listingName}
                        </Heading>
                        <Text mb="0" color="white" size="sm">
                            {props.city}
                        </Text>
                    </Box>
                        {props.listingImage && props.listingImage.publicURL.includes('.svg') ? (
                        <Box p="2">
                            <img src={props.listingImage.publicURL} alt={props.listingImageAlt ? props.listingImageAlt : ''} style={{marginLeft: 'auto', marginRight: 'auto' }}/>
                        </Box>
                    ) 
                    : (
                        <Box m="4">
                            <GatsbyImage image={getImage(props.listingImage)} alt={props.listingImageAlt ? props.listingImageAlt : ''} />
                        </Box>
                    )
                    }
                    
                    <Box p="4">
                        <Text fontSize="sm">{props.listingDescription}</Text>
                    </Box>
                    <Box>
                        {props.listingPhone && (
                        <Flex px="4" mb="2" w="100%" alignItems="center">
                            <Box mr="2" w="8%"> 
                                <PhoneIcon />
                            </Box>
                            <Box><Link href={`tel:${props.listingPhone}`} fontSize="sm">{props.listingPhone}</Link></Box>
                        </Flex> )}
                        {props.listingWebsite && (
                            <Flex px="4" mb="2" w="100%" alignItems="center">
                            <Box mr="2" w="8%">
                                <LaptopMobileIcon />
                            </Box>
                            <Box>
                                <Link href={props.listingWebsite} target="_blank" rel="noopener noreferrer" fontSize="sm">{props.listingWebsite}</Link>
                            </Box>
                        </Flex>
                            )}
                        
                        {props.listingStreet && (
                        <Flex px="4" mb="2" w="100%" alignItems="center">
                            <Box mr="2" w="8%">
                                <MapIcon />
                            </Box>
                            <Box><Link href={props.mapLink} target="_blank" rel="noopener noreferrer" fontSize="sm">
                                {props.listingStreet}<br />{props.listingAddress2 && <>{props.listingAddress2} <br /></>} 
                                {props.listingCity}, {props.listingCity === "Alta" ? ('WY') : ('ID') } {props.listingCity === "Victor" ? 83455 : props.listingCity === "Driggs" ? 83422 : props.listingCity === "Tetonia" ? 83452 : props.listingCity === "Alta" ? 83414 : null }</Link></Box>
                        </Flex> )
}
                    </Box>
                    <Flex w="100%" justifyContent="flex-start" flexWrap="wrap" p="2" mt="auto">
                        {/* Tags are an array of objects, so wrap the key and index in {} */}
                        {props.listingTags.map(({eat_listing_tags, index2}) => {
                            //console.log(eat_listing_tags)
                        return (
                            <>
                            {eat_listing_tags !== null && ( 
                                <TagBox 
                                key={index2}
                                tag={eat_listing_tags}
                            />
                            )}
                           
                            </>
                        )}
                        )}
                    </Flex>
                    </Flex>
        </Box>
        ) : <Box p="4" w={{base: '100%', md: '50%', lg: '33.33%'}} as={motion.div}
        layout 
        initial={{opacity: 0}}
        animate={{ opacity: 1}}
        exit={{ opacity: 0}}
        order="3"
        
        >
        <Flex flexDirection="column" justifyContent="flex-start" w="100%" h="100%"
            border="1px solid" borderColor="gray.200" borderRadius="xl"
            boxShadow="lg" bg="white"
            >
                <Box p="4" borderTopRadius="xl">
                    <Heading as="h3" size="md" fontWeight="bold" color="blue.500" mb="0">
                        {props.listingName}
                    </Heading>
                    <Text mb="0" color="blue.500" size="sm">
                        {props.city}
                    </Text>
                </Box>
                {/* <Box>
                    <GatsbyImage image={getImage(props.listingImage)} alt={props.listingImageAlt} />
                </Box> */}
                <Box p="4">
                    <Text fontSize="sm">{props.listingDescription}</Text>
                </Box>
                <Box>
                    {props.listingPhone && (
                        <Flex px="4" mb="2" w="100%" alignItems="center">
                        <Box mr="2" w="8%">
                            <PhoneIcon />
                        </Box>
                        <Box><Link href={`tel:${props.listingPhone}`} fontSize="sm">{props.listingPhone}</Link></Box>
                    </Flex>
                    )}
                    {props.listingWebsite && (
                        <Flex px="4" mb="2" w="100%" alignItems="center">
                        <Box mr="2" w="8%">
                            <LaptopMobileIcon />
                        </Box>
                        <Box>
                            <Link href={props.listingWebsite} target="_blank" rel="noopener noreferrer" fontSize="sm">{props.listingWebsite}</Link>
                        </Box>
                    </Flex>
                    )}
                    {props.listingStreet && (
                    <Flex px="4" mb="2" w="100%" alignItems="center">
                        <Box mr="2" w="8%">
                            <MapIcon />
                        </Box>
                        <Box><Link href={props.mapLink} target="_blank" rel="noopener noreferrer" fontSize="sm">
                                {props.listingStreet}<br />{props.listingAddress2 && <>{props.listingAddress2} <br /></>} 
                                {props.listingCity}, {props.listingCity === "Alta" ? ('WY') : ('ID') } {props.listingCity === "Victor" ? 83455 : props.listingCity === "Driggs" ? 83422 : props.listingCity === "Tetonia" ? 83452 : props.listingCity === "Alta" ? 83414 : null }</Link></Box>
                    </Flex>
                    )
}
                </Box>
                <Flex w="100%" justifyContent="flex-start" flexWrap="wrap" p="2" mt="auto">
                    {/* Tags are an array of objects, so wrap the key and index in {} */}
                    {props.listingTags.map(({eat_listing_tags, idx}) => {
                        //console.log(eat_listing_tags)
                    return (
                        <>
                            {eat_listing_tags !== null && ( 
                                <TagBox 
                                key={idx}
                                tag={eat_listing_tags}
                            />
                            )}
                        </>
                    )}
                    )}
                </Flex>
                </Flex>
    </Box> }
        
        </>
    )
}