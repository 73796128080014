import React from "react";

import styled from '@emotion/styled';

  const Icon = styled.svg`
  width: ${props => props.width ? props.width : '15px' };
  height: auto;
  .st0 {
    fill: #0069A6;
  }
`

const MapIcon = (props) => {
  return (
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.831 31.775">
  <path id="map-marker-alt-solid" d="M10.691,31.134C1.674,18.061,0,16.72,0,11.916a11.916,11.916,0,0,1,23.831,0c0,4.8-1.674,6.146-10.691,19.218a1.49,1.49,0,0,1-2.449,0ZM11.916,16.88a4.965,4.965,0,1,0-4.965-4.965A4.965,4.965,0,0,0,11.916,16.88Z" className="st0"/>
</Icon>
  );
};

export default MapIcon;