import React from "react";

import styled from '@emotion/styled';

  const Icon = styled.svg`
  width: ${props => props.width ? props.width : '18px' };
  height: auto;
  .st0 {
    fill: #0069A6;
  }
`

const PhoneIcon = (props) => {
  return (
<Icon version="1.1" id="Phone" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 29 29" {...props}>
<path id="Path_110" className="st0" d="M16.8,20.7l3.1-2.8c0.2-0.2,0.5-0.3,0.8-0.3l0.5,0.3l7.2,3.4c0.5,0.3,0.5,0.5,0.5,1
	c-0.3,2.1-0.8,3.6-2.1,4.7c-1.5,1.4-3.4,2.1-5.4,2.1c-2.5,0-5-0.6-7.2-1.8C11.6,25.8,9.2,24,7,22c-1.4-1.8-2.7-3.7-3.9-5.7
	c-1-1.8-1.9-3.7-2.6-5.7C0.1,9-0.1,7.3,0,5.7c0.2-1.5,0.8-2.8,1.8-3.9c1-1,2.6-1.6,4.9-1.8c0.4,0,0.8,0.2,1,0.5l3.9,7.2
	c0,0.2,0.1,0.4,0.3,0.5c0,0.3-0.1,0.6-0.3,0.8l-3.4,3.1c-0.3,0.4-0.3,0.9,0,1.3c0.9,1.5,1.9,2.9,3.1,4.1c1.3,1.3,2.6,2.4,4.1,3.4
	C16,21.2,16.6,21.2,16.8,20.7z"/>
</Icon>
  );
};

export default PhoneIcon;

